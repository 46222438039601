import auth from '../../middlewares/auth'

export default {
  path: '/orders',
  component: () => import('@/views/orders/index.vue'),
  children: [
    {
      path: '',
      name: 'OrderList',
      component: () => import('@/views/orders/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'OrderCreate',
      component: () => import('@/views/orders/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'OrderEdit',
      component: () => import('@/views/orders/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'OrderDetail',
      component: () => import('@/views/orders/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
