<template>
  <v-snackbar
    v-model="snackbar.active"
    :timeout="snackbar.timeout"
    color="rgba(0, 0, 0, 0)"
    elevation="0"
    top>
    <v-alert
      border="right"
      colored-border
      :type="snackbar.type"
      elevation="3"
      dense>
      <p class="text-capitalize mb-0">
        {{ snackbar.text }}
      </p>
      <template #close>
        <v-btn
          color="white"
          small
          icon
          @click="closeSnackBar()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-alert>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      snackbar: 'Snackbar/snackbar'
    })
  },
  methods: {
    ...mapActions({
      closeSnackbar: 'Snackbar/closeSnackbar'
    }),
    closeSnackBar () {
      this.closeSnackbar()
    }
  }
}
</script>
