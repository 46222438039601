const state = {
  dialog: {
    active: false,
    title: '',
    text: '',
    onConfirm: null
  }
}

const actions = {
  setDialog ({ commit }, payload) {
    commit('SET_DIALOG', payload)
  }
}

const mutations = {
  SET_DIALOG (state, payload) {
    state.dialog = payload
  }
}

const getters = {
  dialog: (state) => state.dialog
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
