import auth from '../../middlewares/auth'

export default {
  path: '/members',
  component: () => import('@/views/members/index.vue'),
  children: [
    {
      path: '',
      name: 'MemberList',
      component: () => import('@/views/members/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'MemberCreate',
      component: () => import('@/views/members/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'MemberEdit',
      component: () => import('@/views/members/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'MemberDetail',
      component: () => import('@/views/members/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
