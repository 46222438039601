import auth from '../../middlewares/auth'

export default {
  path: '/tours',
  component: () => import('@/views/tours/index.vue'),
  children: [
    {
      path: '',
      name: 'TourList',
      component: () => import('@/views/tours/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'TourCreate',
      component: () => import('@/views/tours/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'TourEdit',
      component: () => import('@/views/tours/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'TourDetail',
      component: () => import('@/views/tours/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
