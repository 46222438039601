import Vue from 'vue'
import Vuex from 'vuex'

import ConfirmDialog from './modules/ConfirmDialog'
import Loading from './modules/Loading'
import Snackbar from './modules/Snackbar'
import User from './modules/User'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ConfirmDialog,
    Loading,
    Snackbar,
    User
  }
})
