import auth from '../../middlewares/auth'

export default {
  path: '/settings/packages',
  component: () => import('@/views/settings/index.vue'),
  children: [
    {
      path: '',
      name: 'SettingsPackage',
      component: () => import('@/views/settings/pages/packages/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'continents',
      name: 'SettingsContinent',
      component: () => import('@/views/settings/pages/packages/Continents.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'continents/create',
      name: 'SettingsContinentCreate',
      component: () => import('@/views/settings/pages/packages/ContinentForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'continents/edit/:id',
      name: 'SettingsContinentEdit',
      component: () => import('@/views/settings/pages/packages/ContinentForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tags',
      name: 'SettingsTag',
      component: () => import('@/views/settings/pages/packages/Tags.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tags/create',
      name: 'SettingsTagCreate',
      component: () => import('@/views/settings/pages/packages/TagForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tags/edit/:id',
      name: 'SettingsTagEdit',
      component: () => import('@/views/settings/pages/packages/TagForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tour-types',
      name: 'SettingsTourType',
      component: () => import('@/views/settings/pages/packages/TourTypes.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tour-types/create',
      name: 'SettingsTourTypeCreate',
      component: () => import('@/views/settings/pages/packages/TourTypeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'tour-types/edit/:id',
      name: 'SettingsTourTypeEdit',
      component: () => import('@/views/settings/pages/packages/TourTypeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'resident-types',
      name: 'SettingsResidentType',
      component: () => import('@/views/settings/pages/packages/ResidentTypes.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'resident-types/create',
      name: 'SettingsResidentTypeCreate',
      component: () => import('@/views/settings/pages/packages/ResidentTypeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'resident-types/edit/:id',
      name: 'SettingsResidentTypeEdit',
      component: () => import('@/views/settings/pages/packages/ResidentTypeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'airlines',
      name: 'SettingsAirline',
      component: () => import('@/views/settings/pages/packages/Airlines.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'airlines/create',
      name: 'SettingsAirlineCreate',
      component: () => import('@/views/settings/pages/packages/AirlineForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'airlines/edit/:id',
      name: 'SettingsAirlineEdit',
      component: () => import('@/views/settings/pages/packages/AirlineForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'amenities',
      name: 'SettingsAmenity',
      component: () => import('@/views/settings/pages/packages/Amenities.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'amenities/create',
      name: 'SettingsAmenityCreate',
      component: () => import('@/views/settings/pages/packages/AmenityForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'amenities/edit/:id',
      name: 'SettingsAmenityEdit',
      component: () => import('@/views/settings/pages/packages/AmenityForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'service-rates',
      name: 'SettingsServiceRate',
      component: () => import('@/views/settings/pages/packages/ServiceRates.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'service-rates/create',
      name: 'SettingsServiceRateCreate',
      component: () => import('@/views/settings/pages/packages/ServiceRateForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'service-rates/edit/:id',
      name: 'SettingsServiceRateEdit',
      component: () => import('@/views/settings/pages/packages/ServiceRateForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'currencies',
      name: 'SettingsCurrency',
      component: () => import('@/views/settings/pages/packages/Currencies.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'currencies/create',
      name: 'SettingsCurrencyCreate',
      component: () => import('@/views/settings/pages/packages/CurrencyForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'currencies/edit/:id',
      name: 'SettingsCurrencyEdit',
      component: () => import('@/views/settings/pages/packages/CurrencyForm.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
