<template>
  <div class="login-container">
    <v-dialog
      :value="true"
      persistent
      max-width="360">
      <v-card>
        <v-card-title
          class="mb-3 font-weight-bold"
          style="font-size: 24px;">
          เข้าสู่ระบบ
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validate">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.username"
                  :rules="textBoxRules"
                  label="ชื่อผู้ใช้"
                  color="secondary"
                  dense
                  outlined
                  :disabled="loading"
                  @keydown.enter="signIn()" />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.password"
                  :rules="textBoxRules"
                  type="password"
                  label="รหัสผ่าน"
                  color="secondary"
                  dense
                  outlined
                  :disabled="loading"
                  @keydown.enter="signIn()" />
              </v-col>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      @click="signIn()">
                      เข้าสู่ระบบ
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UsersProvider from '../resources/users.provider'
import AuthProvider from '../resources/auth.provider'
import { setAuthUser, clearAuth, getAuthDecode } from '../utils/auth.utils'

const AuthService = new AuthProvider()
const UsersService = new UsersProvider()

export default {
  data () {
    return {
      loading: false,
      validate: false,
      formData: {
        username: '',
        password: ''
      },
      textBoxRules: [
        (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
      ]
    }
  },
  async mounted () {
    if (this.$route.name === 'Logout') {
      this.setUser(null)
      clearAuth()
    } else if (this.$route.name === 'Login' && getAuthDecode()) {
      const { data: user } = await UsersService.getUserById(getAuthDecode().sub)
      this.setUser(user)

      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar',
      setUser: 'User/setUser'
    }),
    async signIn () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true

          const data = await AuthService.login(this.formData)

          if (data) {
            setAuthUser(data.accessToken)

            const { data: user } = await UsersService.getUserById(data.data.sub)
            this.setUser(user)

            this.$router.push({ name: 'Home' })
            this.formData = {
              username: '',
              password: ''
            }
          }
        } catch (error) {
          console.error('signIn', error)

          const errorMessage = error?.statusCode === 404
            ? 'ไม่พบชื่อผู้ใช้งาน หรือรหัสผ่านผิด'
            : (error?.message || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')

          this.setSnackbar({
            active: true,
            text: errorMessage,
            type: 'error',
            timeout: 2000
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
