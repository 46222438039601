import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class UsersProvider extends HttpRequest {
  getAllUsers (query) {
    this.setHeader(getAuthToken())
    return this.get('/users', query)
  }

  getUserById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/users/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/users', payload)
  }

  updateUserById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/users/${payload.id}`, payload)
  }

  deleteUserById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/users/${id}`)
  }
}

export default UsersProvider
