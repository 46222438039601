<template>
  <div
    v-if="loading"
    class="loading-container">
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate />
  </div>
</template>

<script>
export default {
  computed: {
    loading () {
      return this.$store.getters['Loading/loading']
    }
  }
}
</script>

<style scoped>
.loading-container {
  position: fixed;
  z-index: 299;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
