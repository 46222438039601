import auth from '../../middlewares/auth'

export default {
  path: '/vendors',
  component: () => import('@/views/vendors/index.vue'),
  children: [
    {
      path: '',
      name: 'VendorList',
      component: () => import('@/views/vendors/pages/List.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'create',
      name: 'VendorCreate',
      component: () => import('@/views/vendors/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'edit/:id',
      name: 'VendorEdit',
      component: () => import('@/views/vendors/pages/Form.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'detail/:id',
      name: 'VendorDetail',
      component: () => import('@/views/vendors/pages/Detail.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
