import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#026EAA',
        secondary: '#333333',
        white: '#FFFFFF',
        accent: '#828282',
        teal: '#07BAC6',
        gold: '#FFDF7D',
        error: '#EB5757',
        info: '#DED6C8',
        success: '#27AE60',
        warning: '#FF9C34'
      }
    }
  }
})
