import jwtDecode from 'jwt-decode'
import * as Cookies from 'js-cookie'

const storageKey = process.env.VUE_APP_JWT_TOKEN

export const getAuth = () => {
  const auth = Cookies.getJSON(storageKey)
  return auth
}

export const getAuthDecode = () => {
  const auth = Cookies.getJSON(storageKey)
  return auth ? jwtDecode(auth) : null
}

export const setAuthUser = (token) => {
  const decoded = jwtDecode(token)
  const date = decoded.exp * 1000
  Cookies.set(storageKey, token, {
    expires: new Date(date)
  })
  return true
}

export const clearAuth = () => {
  Cookies.remove(storageKey)
}

export const getAuthToken = () => {
  const token = { key: '', value: '' }

  if (Cookies.getJSON(storageKey)) {
    token.key = 'Authorization'
    token.value = `Bearer ${Cookies.getJSON(storageKey)}`
  }

  return token
}

export default {
  setAuthUser,
  getAuth,
  getAuthToken,
  clearAuth,
  getAuthDecode
}
