const state = {
  snackbar: {
    active: false,
    text: '',
    type: 'info',
    timeout: 2000
  }
}

const actions = {
  setSnackbar ({ commit }, payload) {
    commit('SET_SNACKBAR', payload)
  },
  closeSnackbar ({ commit }) {
    commit('CLOSE_SNACKBAR')
  }
}

const mutations = {
  SET_SNACKBAR (state, payload) {
    state.snackbar = payload
  },
  CLOSE_SNACKBAR (state) {
    state.snackbar.active = false
  }
}

const getters = {
  snackbar: (state) => state.snackbar
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
