const state = {
  user: null
}

const actions = {
  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  }
}

const mutations = {
  SET_USER (state, payload) {
    state.user = payload
  }
}

const getters = {
  user: (state) => state.user,
  username: (state) => state.user?.username || ''
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
