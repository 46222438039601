import auth from '../../middlewares/auth'

export default {
  path: '/settings',
  component: () => import('@/views/settings/index.vue'),
  children: [
    {
      path: '',
      name: 'Settings',
      component: () => import('@/views/settings/pages/Settings.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'members',
      name: 'SettingsMember',
      component: () => import('@/views/settings/pages/members/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'members/rank/:rank',
      name: 'SettingsMemberRank',
      component: () => import('@/views/settings/pages/members/MemberRankForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'members/condition',
      name: 'SettingsMemberCondition',
      component: () => import('@/views/settings/pages/members/MemberConditionForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'members/privilege/create',
      name: 'SettingsMemberPrivilegeCreate',
      component: () => import('@/views/settings/pages/members/MemberPrivilegeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'members/privilege/edit/:id',
      name: 'SettingsMemberPrivilegeEdit',
      component: () => import('@/views/settings/pages/members/MemberPrivilegeForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'users',
      name: 'SettingsUser',
      component: () => import('@/views/settings/pages/users/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'users/create',
      name: 'SettingsUserCreate',
      component: () => import('@/views/settings/pages/users/UserForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'users/edit/:id',
      name: 'SettingsUserEdit',
      component: () => import('@/views/settings/pages/users/UserForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners',
      name: 'SettingsPartner',
      component: () => import('@/views/settings/pages/partners/index.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners/create',
      name: 'SettingsPartnerCreate',
      component: () => import('@/views/settings/pages/partners/PartnerForm.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      path: 'partners/edit/:id',
      name: 'SettingsPartnerEdit',
      component: () => import('@/views/settings/pages/partners/PartnerForm.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
