<template>
  <div class="home">
    <!-- <v-img
      class="my-auto mx-auto"
      height="70dvh"
      src="@/assets/images/welcome.png" /> -->
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
