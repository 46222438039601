const state = {
  loading: false
}

const actions = {
  setLoading ({ commit }, payload) {
    commit('SET_LOADING', payload)
  }
}

const mutations = {
  SET_LOADING (state, payload) {
    state.loading = payload
  }
}

const getters = {
  loading: (state) => state.loading
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
